import React from 'react';
import { Link } from 'gatsby';

const email = 'pat@kalanso.com';

const IndexPage = () => (
  <div>
    <h1>Get ready for an upgraded Kalanso Biochar experience!</h1>
    <h2>Site temporarily down for maintenance, but back online soon!</h2>
    <p>But don't worry!</p>
    <p>While you wait...</p>
    <p>I've got a treat for you. :)</p>
    <p>What is it?</p>
    <p>Glad you asked.</p>
    <p>It's a very special on-demand class about...</p>
    <p>How to make your soil...</p>
    <p>Low-maintenance</p>
    <p>Fertile</p>
    <p>And alive!</p>
    <p>It's free.</p>
    <p>And while I am biased....</p>
    <p>It's pretty cool.</p>
    <Link to="https://successfulgrowersecrets.com">
      Click Here To Sign Up And Watch
    </Link>
    <p>Your Pal,</p>
    <p>Pat White</p>
    <p>P.S. For questions and inquiries...</p>
    <p>
      Please email: <Link to={`mailto:${email}`}>{email}</Link>
    </p>
    <p>
      Copyright {new Date().getFullYear()} - Kalanso, LLC - All Rights Reserved.
    </p>
    <p>58 Day Street, 441213, Somerville, MA 02144</p>
    <ul>
      <li>
        <a href="https://kalanso.myshopify.com/pages/privacy-policy">Privacy</a>
      </li>
      <li>
        <a href="https://kalanso.myshopify.com/pages/terms-and-conditions">
          Terms
        </a>
      </li>
      <li>
        <a href="https://kalanso.myshopify.com/pages/contact-us">Contact</a>
      </li>
    </ul>
  </div>
);

export default IndexPage;
